import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import HeroHeader from "../components/HeroHeader";
import { toPlainText } from "../lib/helpers";
import BlogPost from "../components/BlogPost";
import OurLocations from "../sections/OurLocations";
import FAQs from "../sections/FAQs";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
    image: file(relativePath: { eq: "about-us-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;

  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {post && (
        <Seo
          title={post.title + " | FEAT Fitness"}
          meta_title={post.title}
          meta_desc={toPlainText(post._rawExcerpt)}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
          slug={post.slug}
        />
      )}
      <main className="section">
        <HeroHeader
          title={post.title}
          background_image={data?.image}
          options={[]}
        />
        <div className="my-16 max-w-4xl px-8 text-left m-auto">
          {post && <BlogPost {...post} />}
        </div>

        <OurLocations />
        <FAQs />
      </main>
    </Layout>
  );
};

export default BlogPostTemplate;
