import React from "react";
import Heading from "../Heading";
import SanityPortableText from "../SanityPortableText/SanityPortableText";

function BlogPost(props) {
  const { _rawBody, title, employmentType, subtitle } = props;
  return (
    <>
      <Heading level={2} className="mb-8">
        Apply to be a {title}
      </Heading>

      {_rawBody && <SanityPortableText value={_rawBody} />}
    </>
  );
}

export default BlogPost;
